import { CaretRightOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import type { GameSite } from '@genteras/core-lib/constants/game-sites.constant';
import { whichGameSite } from '@genteras/core-lib/constants/game-sites.constant';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import type { Game } from '@/lib/api/gameLaunch';
import { useGlobalModalContext } from '@/lib/context/global-modal-context';
import useUser from '@/lib/hooks/useUser';
import { windowOpenGame } from '@/lib/utils/gameLauncher';

const PlayBtn = styled.div({
  zIndex: 5,
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: '#0000007a',
  color: '#fff',
  borderRadius: 24,
  cursor: 'pointer',
  display: 'none',
  fontSize: 70,
  ['> div']: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const HoverEffect = styled.div({});

const GameItemContainer = styled.div({
  position: 'relative',
  aspectRatio: '216/182',
  borderRadius: '15px',
  [`:hover ${PlayBtn}`]: {
    display: 'inline-block',
  },
  [`:hover ${HoverEffect}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    cursor: 'pointer',
    borderRadius: '15px',
    background: '#000000b0',
  },
});

const GameImage = styled(Image)({
  borderRadius: '15px',
});

type Props = {
  game: Game;
};

const GameItem = (props: Props) => {
  const { game } = props;

  const router = useRouter();
  const { locale } = router;

  const { t } = useTranslation();
  const { showLoginModal } = useGlobalModalContext();
  const { user } = useUser();

  const openGame = async (
    gameSite: GameSite,
    gameCode?: string | null,
    gameType?: string | null
  ) => {
    if (!locale) {
      throw new Error('Locale is missing');
    }
    const openGameSite = user.gameSiteSettings.find(
      (x) => whichGameSite(gameSite) === whichGameSite(x.gameSite)
    )?.gameSite;
    if (!openGameSite) {
      return;
    }
    await windowOpenGame(openGameSite, gameCode, gameType, t, locale);
  };

  const getImagePath = () => {
    const siteMap: { [key in string]: string } = {
      sp: 'slot',
    };

    const type =
      game.gameSite in siteMap ? siteMap[game.gameSite] : game.gameType;
    return `/images/games/${type}/${game.imgUrl}`;
  };
  return (
    <GameItemContainer
      onClick={() => {
        if (!user.username) {
          return showLoginModal();
        }

        if (user.username) {
          openGame(game.gameSite, game.launchCode, game.gameType);
        }
      }}
    >
      <GameImage
        src={getImagePath()}
        layout="fill"
        objectFit="cover"
        width="216"
        height="182"
        style={{ width: '100%' }}
      />

      <PlayBtn>
        <div>
          <CaretRightOutlined />
        </div>
      </PlayBtn>
      <HoverEffect />
    </GameItemContainer>
  );
};

export default GameItem;
