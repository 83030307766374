import { Carousel } from 'antd';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

export const CarouselBanner = () => {
  const { t } = useTranslation();

  const images = [
    '/images/games/sa-roulette-banner.png',
    '/images/games/sa-baccarat-banner.png',
    '/images/games/sp-slot-banner.png',
    '/images/games/sp-other-games-banner.png',
  ];
  return (
    <div className="carousel-container">
      <Carousel autoplay>
        {images.map((image) => (
          <div key={`carousel-banner-${image}`}>
            <Image src={t(image)} width={1280} height={548} sizes="1" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
